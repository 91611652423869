import { CALL_API } from '../middleware/api';
import * as types from '../constants/actiontypes.js';
import * as util from '../utils/permissions-v2.js';
import P from 'bluebird';
import { getEventsForOrder } from '../services/events';
import { flattenEventQueryResults } from '../decorators/events';
import { getOrderIds } from '../decorators/item';
import { callFetch } from '../services/serviceHelpers';
import { getFulfillmentExpectationView } from '../services/fulfillmentExpectations';
import cloneDeep from 'lodash/cloneDeep';
import get from 'lodash/get';
import uniq from 'lodash/uniq';
import uniqBy from 'lodash/uniqBy';
import ensurePermissions from '../middleware/ensurePermissions';

export function loadItemServiceOrder(orderId) {
  return dispatch => {
    return dispatch({
      [CALL_API]: {
        types: [types.LOAD_ORDER_ITEMS_REQUEST, types.LOAD_ORDER_ITEMS_SUCCESS, types.LOAD_ORDER_ITEMS_FAILURE],
        endpoint: `${process.env.REACT_APP_ITEM_SERVICE_URL}/v1/items?orderId=${orderId}`,
        method: 'GET',
      },
    });
  };
}

export function loadMerchantOrder(orderId) {
  return dispatch => {
    return dispatch({
      [CALL_API]: {
        types: [
          types.LOAD_MERCHANT_ORDER_REQUEST,
          types.LOAD_MERCHANT_ORDER_SUCCESS,
          types.LOAD_MERCHANT_ORDER_FAILURE,
        ],
        endpoint: `${process.env.REACT_APP_AGGREGATION_URL}/v1/orders/${orderId}`,
        method: 'GET',
      },
    });
  };
}

export function loadEventsForOrder(orderId) {
  return dispatch => {
    dispatch({ type: types.LOAD_EVENTS_REQUEST });
    let rawEvents;
    return getEventsForOrder(orderId)
      .then(events => {
        rawEvents = cloneDeep(events);
        rawEvents.forEach(event => {
          delete event.event.attempts;
        });
        return events;
      })
      .then(flattenEventQueryResults)
      .then(decoratedEvents => dispatch({ type: types.LOAD_EVENTS_SUCCESS, response: { decoratedEvents, rawEvents } }))
      .catch(e => dispatch({ type: types.LOAD_EVENTS_FAILURE, error: e }));
  };
}

// load all the claims against an order, and the reorders based on those claims
export const loadClaims = claimLinks => async dispatch => {
  try {
    const allResults = await P.map(claimLinks, link => callFetch(link), { concurrency: 5 });
    const claims = uniqBy(
      allResults.flatMap(result => result.results),
      'id'
    );

    // get the claims for this order
    const claimsSummary = claims.map(claim => ({ id: claim.id, url: get(claim, '_links.ui.href') }));

    // get the reorders
    const reorderItemIds = uniq(
      claims
        .flatMap(claim => claim.items || [])
        .flatMap(item => item.reorders || [])
        .map(reorder => reorder.itemId)
        .filter(x => x)
    );
    let claimReordersSummary;
    if (reorderItemIds.length) {
      const claimReorderItems = await P.map(
        reorderItemIds,
        itemId => callFetch(`${process.env.REACT_APP_ITEM_SERVICE_URL}/v1/items/${itemId}`),
        { concurrency: 5 }
      );
      claimReordersSummary = getOrderIds(claimReorderItems);
    }

    dispatch({ type: types.LOAD_CLAIMS_SUCCESS, claimsSummary, claimReordersSummary });
  } catch (error) {
    console.warn(error);
  }
};

// load all the complaints against an order, and the reorders based on those complaints
export const loadComplaints = complaintLinks => async dispatch => {
  try {
    const allResults = await P.map(complaintLinks, link => callFetch(link), { concurrency: 5 });
    const complaints = uniqBy(
      allResults.flatMap(result => result.results),
      'complaintId'
    );

    // get the complaints for this order
    const complaintsSummary = complaints.map(complaint => ({
      id: complaint.complaintId,
      url: get(complaint, '_links.ui.href'),
    }));

    // get the reorders
    const reorderLinks = uniq(
      complaints
        .flatMap(complaint => complaint.items || [])
        .flatMap(item => item.reorders || [])
        .map(reorder => reorder.href)
        .filter(x => x)
    );
    let complaintReordersSummary;
    if (reorderLinks.length) {
      const complaintReorderItems = await P.map(reorderLinks, link => callFetch(link), { concurrency: 5 });
      complaintReordersSummary = getOrderIds(complaintReorderItems);
    }

    dispatch({ type: types.LOAD_COMPLAINTS_SUCCESS, complaintsSummary, complaintReordersSummary });
  } catch (error) {
    console.warn(error);
  }
};

// if this is a reorder due to a claim or complaint, get all the original orders
export const loadOriginalOrders = (reorderClaimLinks, reorderComplaintLinks) => async dispatch => {
  try {
    const claims = await P.map(reorderClaimLinks, link => callFetch(link), { concurrency: 5 });
    const complaints = await P.map(reorderComplaintLinks, link => callFetch(link), { concurrency: 5 });

    const claimItemLinks = uniq(
      claims
        .flatMap(claim => get(claim, '_links.items', []))
        .map(link => link.href)
        .filter(x => x)
    );
    const complaintItemLinks = uniq(
      complaints
        .flatMap(complaint => complaint.items)
        .map(item => get(item, '_links.item.href'))
        .filter(x => x)
    );

    const [claimItems, complaintItems] = await Promise.all([
      P.map(claimItemLinks, link => callFetch(link), { concurrency: 5 }),
      P.map(complaintItemLinks, link => callFetch(link), { concurrency: 5 }),
    ]);

    const claimOriginalOrdersSummary = getOrderIds(claimItems);
    const complaintOriginalOrdersSummary = getOrderIds(complaintItems);

    dispatch({ type: types.LOAD_ORIGINAL_ORDERS_SUCCESS, claimOriginalOrdersSummary, complaintOriginalOrdersSummary });
  } catch (error) {
    console.warn(error);
  }
};

export function loadBuyerShippingPricingForItem(pricingLink, itemId) {
  return dispatch => {
    dispatch({ type: types.LOAD_ORDER_SHIPPING_PRICING_REQUEST });
    return callFetch(pricingLink)
      .then(buyerShippingPrice =>
        dispatch({
          type: types.LOAD_ORDER_SHIPPING_PRICING_SUCCESS,
          response: buyerShippingPrice,
          actionContext: itemId,
        })
      )
      .catch(error => dispatch({ type: types.LOAD_ORDER_SHIPPING_PRICING_FAILURE, error, actionContext: itemId }));
  };
}

export function loadBuyerProductPricingForItem(pricingLink, itemId) {
  return dispatch => {
    dispatch({ type: types.LOAD_ORDER_PRODUCT_PRICING_REQUEST });
    return callFetch(pricingLink)
      .then(buyerProductPrice =>
        dispatch({ type: types.LOAD_ORDER_PRODUCT_PRICING_SUCCESS, response: buyerProductPrice, actionContext: itemId })
      )
      .catch(error => dispatch({ type: types.LOAD_ORDER_PRODUCT_PRICING_FAILURE, error, actionContext: itemId }));
  };
}

export function loadOrderActions(orderId, merchantId) {
  return (dispatch, getState, subscribe) => {
    return ensurePermissions(dispatch, getState, subscribe).then(permissions => {
      return dispatch({
        [CALL_API]: {
          types: [types.LOAD_ACTIONS_REQUEST, types.LOAD_ACTIONS_SUCCESS, types.LOAD_ACTIONS_FAILURE],
          endpoint: `${process.env.REACT_APP_AGGREGATION_URL}/v1/orders/${orderId}/actions`,
          method: 'GET',
          checkPermission: () => util.hasMerchantReadPermission(permissions.permissions, merchantId),
        },
      });
    });
  };
}

const optimizedFulfillerLookup = async (fulfillerId, baseUrl, lookupMap) => {
  if (lookupMap[fulfillerId]) {
    return lookupMap[fulfillerId];
  }

  return callFetch(`${baseUrl}/v1/fulfillers/${fulfillerId}`);
};

export function loadFulfiller(fulfillerId) {
  return async (dispatch, getState) => {
    dispatch({ type: types.LOAD_FULFILLER_REQUEST });

    try {
      const {
        fulfillers: { fulfillersMap },
      } = getState();
      const fulfiller = await optimizedFulfillerLookup(
        fulfillerId,
        process.env.REACT_APP_FULFILLER_IDENTITY_URL,
        fulfillersMap
      );

      dispatch({ type: types.LOAD_FULFILLER_SUCCESS, response: fulfiller });
    } catch (error) {
      dispatch({ type: types.LOAD_FULFILLER_FAILURE, error, actionContext: fulfillerId });
    }
  };
}

export function loadFulfillerDetails(fulfillmentConfiguration) {
  return async (dispatch, getState) => {
    dispatch({ type: types.LOAD_FULFILLER_DETAILS_REQUEST });

    try {
      const {
        fulfillers: { fulfillerDetailsMap },
      } = getState();
      const expectations = fulfillerDetailsMap[fulfillmentConfiguration]
        ? fulfillerDetailsMap[fulfillmentConfiguration]
        : await getExpectations(fulfillmentConfiguration);

      dispatch({ type: types.LOAD_FULFILLER_DETAILS_SUCCESS, response: { expectations, fulfillmentConfiguration } });
    } catch (error) {
      dispatch({ type: types.LOAD_FULFILLER_DETAILS_FAILURE, error, actionContext: fulfillmentConfiguration });
    }
  };
}

async function getExpectations(fulfillmentConfiguration) {
  const expectations = await getFulfillmentExpectationView(fulfillmentConfiguration);
  return expectations._embedded.processes.concat(expectations._embedded.requirements).reduce((acc, process) => {
    // configurationAlias is camelCase instead of the all lowercase expectationCode
    acc[process.configurationAlias || process.expectationCode] = Boolean(process.supported) || Boolean(process.used);
    return acc;
  }, {});
}

export function loadFulfillerAlternateDetails(itemId) {
  return dispatch => {
    return dispatch({ type: types.LOAD_FULFILLER_NO_PERMISSION, item: itemId, fulfillerPermission: false });
  };
}

export function loadOrder(orderId) {
  return dispatch => {
    dispatch(loadMerchantOrder(orderId));
    dispatch(loadItemServiceOrder(orderId));
  };
}

export function loadProductManufacturing(itemId, productConfigUrl) {
  return dispatch => {
    const queryParams = `product?productConfigurationUrl=${productConfigUrl}&showAttributeSource=true`;
    const endpoint = `${process.env.REACT_APP_PRODUCT_MANUFACTURING_URL}/v1/productmanufacturingdatarequest/`;
    return callFetch(endpoint + queryParams).then(response => {
      dispatch({
        type: types.LOAD_ITEM_PRODUCT_MANUFACTURING_SUCCESS,
        actionContext: itemId,
        productManufacturingData: response,
      });
    });
  };
}

export const loadPrintJob = (itemId, printJobLink) => async dispatch => {
  try {
    const printJob = await callFetch(printJobLink);
    dispatch({
      type: types.LOAD_PRINT_JOB_SUCCESS,
      response: printJob,
      actionContext: itemId,
    });
  } catch (error) {
    dispatch({
      type: types.LOAD_PRINT_JOB_FAILURE,
      error,
      actionContext: itemId,
    });
  }
};

export function resetOrder() {
  return { type: types.RESET_ORDER };
}

export const reloadOrder = () => (dispatch, getState) => {
  const orderId = get(getState(), 'order.orderId');
  if (orderId) {
    dispatch(resetOrder());
    dispatch(loadOrder(orderId));
  }
};

export const orderItemsFormatter = order => ({
  ...order,
  items: Object.values(order.items),
});
