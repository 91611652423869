import React, { useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import Button from '@cimpress/react-components/lib/Button';
import Checkbox from '@cimpress/react-components/lib/Checkbox';
import Modal from '@cimpress/react-components/lib/Modal';
import Spinner from '@cimpress/react-components/lib/shapes/Spinner';
import Alert from '@cimpress/react-components/lib/Alert';
import ActionLink from '../../shared/ActionLink';
import RadioGroup from '@cimpress/react-components/lib/RadioGroup';
import Radio from '@cimpress/react-components/lib/Radio';
import get from 'lodash/get';

import BulkReorderResults from '../../bulkReorder/BulkReorderResults';
import { quickReorder, clearBulkReorder } from '../../../actions/bulkReorderActions';
import { trackEvent, TrackingEventTitles, TrackingEventPropertiesNames } from '../../../analytics';

const PlaceReorderModal = ({ order }) => {
  const intl = useIntl();
  const itemArray = Object.values(order.items);
  const largeOrder = itemArray.length > 20;
  const defaultSplitSize = 10;
  const defaultOrderType = largeOrder ? 'split' : 'quick';
  const [showModal, setShowModal] = useState(false);
  const [shouldKeepOriginalPromiseDate, setShouldKeepOriginalPromiseDate] = useState(false);
  const [shouldUnlinkFromOriginalOrder, setShouldUnlinkFromOriginalOrder] = useState(false);
  const [reorderType, setReorderType] = useState(defaultOrderType);
  const [itemSplitNumber, setItemSplitNumber] = useState(defaultSplitSize);
  const submitting = useSelector(state => get(state, 'bulkReorder.submitting'));
  const submitted = useSelector(state => get(state, 'bulkReorder.submitted'));
  const loading = useSelector(state => get(state, 'bulkReorder.loading'));
  const dispatch = useDispatch();

  const close = () => {
    dispatch(clearBulkReorder());
    setShouldKeepOriginalPromiseDate(false);
    setShouldUnlinkFromOriginalOrder(false);
    setShowModal(false);
  };

  const open = () => {
    setShowModal(true);

    trackEvent(TrackingEventTitles.PLACE_REORDER_MODAL_OPENED, {
      [TrackingEventPropertiesNames.ORDER_DETAILS.ORDER_ACTIONS.ORDER_ID]: order.orderId,
    });
  };

  const submit = () => {
    if (reorderType === 'split') {
      dispatch(quickReorder({ ...order, items: itemArray }, shouldKeepOriginalPromiseDate, itemSplitNumber));
    } else {
      dispatch(quickReorder({ ...order, items: itemArray }, shouldKeepOriginalPromiseDate));
    }

    trackEvent(TrackingEventTitles.PLACE_REORDER_SUBMITTED, {
      [TrackingEventPropertiesNames.ORDER_DETAILS.ORDER_ACTIONS.ORDER_ID]: order.orderId,
      [TrackingEventPropertiesNames.ORDER_DETAILS.ORDER_ACTIONS.REORDER_TYPE]: reorderType,
      [TrackingEventPropertiesNames.ORDER_DETAILS.ORDER_ACTIONS
        .REORDER_KEEP_PROMISE_DATE]: shouldKeepOriginalPromiseDate,
    });
  };

  const onReorderTypeChange = (_, newType) => {
    setReorderType(newType);
    setShouldKeepOriginalPromiseDate(false);
    setShouldUnlinkFromOriginalOrder(false);
  };
  const onInputChange = e => {
    setItemSplitNumber(e.target.value);
  };

  const ReorderCheckbox = ({ reorderType }) => {
    if (reorderType === 'split' || reorderType === 'quick') {
      return (
        <div style={{ display: 'flex', alignItems: 'flex-start' }}>
          <Checkbox
            label={<FormattedMessage id="BulkReorder.KeepOriginalDateCheckboxLabel" />}
            checked={shouldKeepOriginalPromiseDate}
            payload="keepOriginalPromisedDate"
            onChange={() => setShouldKeepOriginalPromiseDate(!shouldKeepOriginalPromiseDate)}
          />
        </div>
      );
    } else if (reorderType === 'changes') {
      return (
        <Checkbox
          label={<FormattedMessage id="PlaceReorder.UnlinkFromOriginalOrder" />}
          checked={shouldUnlinkFromOriginalOrder}
          payload="unlinkFromOriginalOrder"
          onChange={() => setShouldUnlinkFromOriginalOrder(!shouldUnlinkFromOriginalOrder)}
        />
      );
    } else {
      return null;
    }
  };

  const ReorderButton = () => {
    let reorderButton = null;

    if (reorderType === 'changes') {
      reorderButton = (
        <Button
          variant="primary"
          onClick={() => {
            window.open(
              `${process.env.REACT_APP_OPA_URL}/reorder?orderId=${order.orderId}&unlinkOriginalOrder=${shouldUnlinkFromOriginalOrder}`,
              '_newtab'
            );
            return null;
          }}>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <FormattedMessage id="OrderActions.PlaceReorder" />
          </div>
        </Button>
      );
    } else {
      reorderButton = (
        <>
          {!submitted && !loading && (
            <Button variant="primary" onClick={submit} disabled={submitting}>
              <div style={{ display: 'flex', alignItems: 'center' }}>
                {submitting && (
                  <div style={{ marginRight: '5px' }}>
                    <Spinner className="no-padding" size="small" />
                  </div>
                )}
                <FormattedMessage id="OrderActions.PlaceReorder" />
              </div>
            </Button>
          )}
        </>
      );
    }
    return reorderButton;
  };

  const ModalFooter = () => {
    return (
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <div>
          <ReorderCheckbox reorderType={reorderType} />
        </div>
        <div>
          <ReorderButton />
          <button className="btn btn-default" onClick={close}>
            <FormattedMessage id="Global.Close" />
          </button>
        </div>
      </div>
    );
  };

  const ReorderRadioGroup = () => {
    let radioGroup = null;

    if (largeOrder) {
      radioGroup = (
        <>
          <Alert
            status="warning"
            message={<FormattedMessage id="PlaceReorder.LargeOrderWarning" />}
            dismissible={false}
          />
          <div>
            <RadioGroup onChange={onReorderTypeChange} defaultSelected={reorderType} name="placeReorder">
              <Radio label={<FormattedMessage id="PlaceReorder.SplitOrder" />} value="split" />
              <Radio label={<FormattedMessage id="PlaceReorder.QuickReorder" />} value="quick" />
              <Radio label={<FormattedMessage id="PlaceReorder.WithOPA" />} value="changes" />
            </RadioGroup>
          </div>
          {reorderType === 'split' && (
            <div>
              <FormattedMessage id="PlaceReorder.SplitOrderBy" />
              <input type="number" className="form-control" placeholder={defaultSplitSize} onChange={onInputChange} />
            </div>
          )}
        </>
      );
    } else {
      radioGroup = (
        <div>
          <RadioGroup onChange={onReorderTypeChange} defaultSelected={reorderType} name="placeReorder">
            <Radio label={<FormattedMessage id="PlaceReorder.QuickReorder" />} value="quick" />
            <Radio label={<FormattedMessage id="PlaceReorder.WithOPA" />} value="changes" />
          </RadioGroup>
        </div>
      );
    }
    return radioGroup;
  };

  return (
    <div>
      <ActionLink
        text={<FormattedMessage id="OrderActions.PlaceReorder" />}
        action={open}
        disabledMessage={<FormattedMessage id="OrderActions.PlaceReorder" />}
      />
      <Modal
        size="lg"
        show={showModal}
        onRequestHide={close}
        closeOnOutsideClick
        closeButton
        title={<FormattedMessage id="OrderActions.PlaceReorder" />}
        footer={<ModalFooter />}>
        {submitted ? (
          <BulkReorderResults />
        ) : (
          <>
            <ReorderRadioGroup />
            <Alert
              status={'warning'}
              message={intl.formatMessage({ id: 'PlaceReorder.LinkWarning' })}
              dismissible={false}
              style={
                reorderType !== 'changes' || shouldUnlinkFromOriginalOrder
                  ? { visibility: 'hidden' }
                  : { visibility: 'visible' }
              }
            />
          </>
        )}
      </Modal>
    </div>
  );
};

PlaceReorderModal.propTypes = {
  order: PropTypes.object.isRequired,
};

export default PlaceReorderModal;
