/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { get, map } from 'lodash';
import { FlexBox, Tooltip } from '@cimpress/react-components';
import IconInformationCircle from '@cimpress-technology/react-streamline-icons/lib/IconInformationCircle';

import { decoratedItemShape } from '../propTypes';
import { FILTER_VISIBILITY_ITEM_COUNT } from '../../constants/filter';
import { trackEvent, TrackingEventTitles, TrackingEventPropertiesNames } from '../../analytics';
import { SuspendedOrderItemsTable } from '@cimpress-technology/react-suspended-orders-item';
import auth from '../../utils/auth';
import { orderItemsFormatter } from '../../actions/orderv2actions';

const OrderHealth = ({ itemsWithIssues, onFilter, style = {}, order = {} }) => {
  const [showSuspendedOrdersModal, setShowSuspendedOrdersModal] = useState(false);

  const onRoutingClick = () => {
    setShowSuspendedOrdersModal(true);
  };

  const onCloseSuspendedOrdersModal = () => {
    setShowSuspendedOrdersModal(false);
  };

  const trackSupportClick = () => {
    trackEvent(TrackingEventTitles.CONTACT_SUPPORT, {
      [TrackingEventPropertiesNames.CONTACT_SUPPORT.LOCATION]: 'Order Health',
    });
  };

  const onItemHealthFilter = items => () => {
    onFilter({ propertyFilters: map(items, i => ({ propertyName: 'itemId', value: i.itemId })) });
  };

  const orderRoutingModalLink =
    order.computedStatus.type === 'routing' ? (
      <a className="clickable" onClick={onRoutingClick}>
        See order routing issues
      </a>
    ) : null;

  const orderId = get(itemsWithIssues, '[0].orderId', '');
  return (
    <FlexBox isVertical spaceBetween style={style}>
      <FlexBox middle noGrow>
        <h6 style={{ marginRight: '5px' }}>
          <FormattedMessage id="Health.Health" />
        </h6>
        <span style={{ flex: 1 }}>
          <Tooltip contents={<FormattedMessage id="Health.HealthDescription" />}>
            <IconInformationCircle className="text-info" weight="fill" />
          </Tooltip>
        </span>
        <a
          onClick={trackSupportClick}
          href={`mailto:PlatformSupport@cimpress.com?Subject=Assistance%20needed%20with%20order%20${orderId}`}>
          <FormattedMessage id="Contact.ContactSupport" />
        </a>
      </FlexBox>
      <FlexBox grow isVertical spaceBetween>
        {itemsWithIssues.length === 1 ? (
          <FormattedMessage id={'Health.ItemWithIssues'} />
        ) : (
          <FormattedMessage id={'Health.ItemsWithIssues'} values={{ numItems: itemsWithIssues.length }} />
        )}
        {itemsWithIssues.length > FILTER_VISIBILITY_ITEM_COUNT && (
          <a className="clickable" onClick={onItemHealthFilter(itemsWithIssues)}>
            <FormattedMessage id={'Health.ShowItemsWithIssues'} />
          </a>
        )}
      </FlexBox>
      {orderRoutingModalLink}
      <SuspendedOrderItemsTable
        accessToken={auth.getAccessToken()}
        userProfile={auth.getProfile()}
        environment={process.env.REACT_APP_ENV === 'loc' ? 'int' : process.env.REACT_APP_ENV}
        order={orderItemsFormatter(order)}
        bodyOnly
        isModal
        isShowingModal={showSuspendedOrdersModal}
        closeModal={onCloseSuspendedOrdersModal}
      />
    </FlexBox>
  );
};

OrderHealth.propTypes = {
  itemsWithIssues: PropTypes.arrayOf(decoratedItemShape).isRequired,
  onFilter: PropTypes.func.isRequired,
  style: PropTypes.object,
  order: PropTypes.object.isRequired,
};

export default OrderHealth;
